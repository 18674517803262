@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  scroll-behavior: smooth !important;
}

/* It makes the HTML progress bar filling smooth when value change. */
progress::-webkit-progress-value {
  transition: 0.6s width ease-out;
}

@layer base {
  .btn-gradient {
    @apply !bg-gradient !bg-[length:300%_300%] hover:saturate-[1.2] shadow duration-100 !border-0 !border-transparent !bg-transparent animate-shimmer disabled:!bg-none disabled:!bg-gray-500/30  !text-white;
  }
  .btn {
    @apply !capitalize;
  }
}

.customer-logo {
  width: 81px;
  margin: 2rem;
  display: inline-block;
}

#hero-title {
  font-family: Tahoma;
}

.highlight {
  font-weight: 900;
  font-size: 200%;
  font-family:'Courier New', Courier, monospace;
}

#hero-content .btn-block {
  width: unset;
}

.purchase-panel {
  border-radius: 15px; /* Rounded corners for the panel */
  padding: 20px; /* Padding inside the panel */
  background-color: #1a202c; /* Dark background color */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5); /* Stronger shadow for depth in dark mode */
  margin-left: auto; /* Auto margins for horizontal centering */
  margin-right: auto;
  border: 1px solid #2d3748; /* Border color for dark mode */
  color: #cbd5e0; /* Light text color for readability in dark mode */
  font-weight: bold; /* Bold text */
  text-decoration: none; /* Underlines the text */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transitions for interactive effects */
  display: block; /* Block display to enable auto margins for centering */
  max-width: fit-content; /* Ensures the panel width fits its content */
}

.purchase-panel:hover {
  transform: translateY(-3px); /* Slightly raises the panel on hover for interactivity */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.6); /* Enhances shadow on hover for visual feedback */
}

.feature-card {
  position: relative; /* Needed to position the SVG container absolutely relative to the card */
}

.svg-container {
  position: absolute;
  bottom: 16px; /* Adjust as needed */
  right: 16px; /* Adjust as needed */
  width: 100px; /* Set the size of the SVG */
  height: 100px; /* Set the size of the SVG */
}

.svg-container img {
  width: 100%;
  height: auto;
}

.svg-container svg {
  position: absolute;
  bottom: 0;
  right: 0;
  transform-origin: bottom right;
  transition: fill 0.3s ease; /* Smooth transition for the fill color change */
}

.feature-card:hover svg.change-color {
  stroke: #006600;
 }

.feature-card:hover svg path.change-color {
  fill: #006600;
  stroke: #006600;
 }

.feature-card:hover svg circle.change-stroke-color,
.feature-card:hover svg path.change-stroke-color {
 stroke: #006600;
}